import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ap-bouncing-balls',
  template: `
    <div class="bouncing-loader" [ngClass]="{ big: viagra, fullscreen: fullScreen }">
      <div [style.background-color]="colorPalette[color]" class="ball"></div>
      <div [style.background-color]="colorPalette[color]" class="ball"></div>
      <div [style.background-color]="colorPalette[color]" class="ball"></div>
    </div>
  `,
  standalone: true,
  styleUrls: ['./bouncing-balls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class BouncingBallsComponent {
  @Input() color: 'blue' | 'white' | 'darkgray' | 'grey' = 'blue';
  @Input() viagra = false;
  @Input() fullScreen = false;

  readonly colorPalette = {
    blue: '#005891',
    white: '#ffffff',
    grey: '#a2acb4',
    darkgray: '#808e99',
  };
}
