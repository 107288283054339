import { ENUM_SmartSearchCaseStatus } from '@api-new/common';
import { RoutePaths } from '@app/app.utils';
import { SharedCaseStatus } from '@shared/enums';

export const ENUM_SmartSearchCaseStatusMap = new Map([
  [ENUM_SmartSearchCaseStatus.SMART_SEARCH_CASE_STATUS_UNSPECIFIED, null],
  [ENUM_SmartSearchCaseStatus.UNRECOGNIZED, null],
  [
    ENUM_SmartSearchCaseStatus.SMART_SEARCH_CASE_STATUS_LEAD,
    {
      route: RoutePaths.CasesLeads,
      sharedCaseStatus: SharedCaseStatus.LEAD,
    },
  ],
  [
    ENUM_SmartSearchCaseStatus.SMART_SEARCH_CASE_STATUS_CLOSED,
    {
      route: RoutePaths.CasesClosed,
      sharedCaseStatus: SharedCaseStatus.CLOSED,
    },
  ],
  [
    ENUM_SmartSearchCaseStatus.SMART_SEARCH_CASE_STATUS_PIPELINE,
    {
      route: RoutePaths.CasesPipeline,
      sharedCaseStatus: SharedCaseStatus.ACTIVE,
    },
  ],
]);
