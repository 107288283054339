import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { RoutePaths } from '@app/app.utils';
import { Store } from '@ngrx/store';
import { ToastService } from '@shared-lib/services/toast.service';
import { selectPlatformDataLoaded } from '@shared/store/platform/platform.selectors';
import { setRedirectUrlAfterSignIn } from '@shared/store/user/user.action';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { selectIsUserDeactivated, selectRedirectUrlAfterSignIn } from '../store/user/user.selectors';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly router: Router,
    private readonly toastr: ToastService,
    private readonly store: Store,
    private readonly authService: AuthService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (state.url.startsWith(RoutePaths.Platform)) {
      return this.authService.authUser().pipe(
        take(1),
        withLatestFrom(this.store.select(selectRedirectUrlAfterSignIn)),
        switchMap(([authUser, redirectUrlAfterSignIn]) => {
          if (authUser == null) {
            // Save URL for deep linking
            this.store.dispatch(setRedirectUrlAfterSignIn({ redirectUrlAfterSignIn: state.url }));
            void this.router.navigate([RoutePaths.Landing, RoutePaths.Login]);
            return of(false);
          }
          if (redirectUrlAfterSignIn != null) {
            // Redirect deep linked page after logging in
            void this.router.navigateByUrl(redirectUrlAfterSignIn);
            this.store.dispatch(setRedirectUrlAfterSignIn({ redirectUrlAfterSignIn: null }));
            return of(true);
          }
          return this.store
            .select(selectPlatformDataLoaded)
            .pipe(filter(Boolean), take(1), withLatestFrom(this.store.select(selectIsUserDeactivated)));
        }),
        map(([_, isUserDeactivated]: [any, boolean]) => {
          if (isUserDeactivated) {
            void this.router.navigate([RoutePaths.Landing, RoutePaths.Activate]);
            return false;
          }
          return true;
        }),
      );
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
