import { HTTP_AP_CreateCompany_Request } from '@api-new/companyservice';
import { CreateCompanyFormRawValues } from '@platform/company/pages/company-create/utils/buildCreateCompanyForm';
import { addressFromToStringAdapter } from '@shared-lib/components/forms/address-form/adapters/address-form.adapter';

export const createCompanyFormToHTTPRequestAdapter = (
  data: CreateCompanyFormRawValues,
  logoUploadUrl: string,
): HTTP_AP_CreateCompany_Request => {
  if (!data.networkId) {
    delete data.companyNotificationSettings.useNetworkNotificationImagesAsFallback;
  }
  delete data.newLogo;
  return {
    ...data,
    logoUploadUrl,
    address: addressFromToStringAdapter(data.address),
    registeredAddress: addressFromToStringAdapter(data.registeredAddress),
    fcaNumber: data.fcaNumber.toString(),
    primaryLightColor: data.primaryLightColor.replace('#', ''),
    secondaryColor: data.secondaryColor.replace('#', ''),
    primaryColor: data.primaryColor.replace('#', ''),
  };
};
