import { EnvironmentModel } from '@shared/models/environment.model';
import { FeatureFlag } from '@shared/models/feature-config.model';

class EnvironmentImpl implements EnvironmentModel {
  production = false;
  sentryDSN = 'https://e8c4edee1f9c4bfb959ec3031090a777@sentry.io/1471235';
  firebaseConfig = {
    apiKey: 'AIzaSyBFBI88V4y_TdU0bml4LyhMz586gKISkdY',
    authDomain: 'dashlydev.firebaseapp.com',
    databaseURL: 'https://dashlydev.firebaseio.com',
    projectId: 'dashlydev',
    storageBucket: 'dashlydev.appspot.com',
    messagingSenderId: '515479880764',
    appId: '1:515479880764:web:ab19ef306aab8d20952f39',
    measurementId: 'G-FBNGTC71Y9',
  };

  featureConfig = {
    [FeatureFlag.LANDCLAN_ADDRESSES]: false,
    [FeatureFlag.CREDIT_REPORT_CLIENT_TABS]: true,
    [FeatureFlag.CREDIT_REPORT_SETTINGS]: true,
    [FeatureFlag.PRE_COMPLETIONS]: true,
  };

  googleCloudPlatformApiKey = 'AIzaSyCEw-HdVyu1zDW9oLjM56yuTeyY3EvTeYU';
  urls = {
    clientPortalUrl: 'https://my.dashly-dev.com',
    adviserPortalUrl: 'https://port.dashly-dev.com',
    universalLeadCaptureUrl: 'https://welcome.dashly-dev.com',
    apiUrl: 'https://api.dashly-dev.com',
    dashlyIQUrl: 'https://iq.dashly-dev.com',
  };
}

export const environment = new EnvironmentImpl();
